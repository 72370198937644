var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-9"},[_c('v-subheader',{staticClass:"title mb-4 text-uppercase pa-0"},[_c('v-icon',{staticClass:"mr-3",on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("mdi-arrow-left")]),_vm._v(" "+_vm._s("Lançamentos")+" ")],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-row',{staticClass:"text-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('DatePicker',{attrs:{"label":'Data Inicial'},on:{"dateValue":function($event){_vm.filter.dateStart = $event}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('DatePicker',{attrs:{"label":'Data Final'},on:{"dateValue":function($event){_vm.filter.dateEnd = $event}}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Pesquisa rápida pelos dados filtrados","single-line":"","hide-details":"","flat":"","disabled":_vm.items.length == 0 ? true : false},model:{value:(_vm.filter.cod),callback:function ($$v) {_vm.$set(_vm.filter, "cod", $$v)},expression:"filter.cod"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"green"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2 mt-3",attrs:{"color":"green","depressed":"","dark":""},on:{"click":function () { return _vm.searchData(); }}},on),[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" BUSCAR ")],1)]}}])},[_c('span',[_vm._v("Buscar pelos termos do filtro")])])],1)],1)],1),_c('v-divider'),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":item.cancelada == 0 && item.status != 'ENTREGUE'
                  ? false
                  : true,"small":"","depressed":"","block":"","dark":"","color":"yellow darken-2"},on:{"click":function () { return _vm.getIdPackpage(item.id_encomenda); }}},on),[_vm._v("Lançar mot. manualmente")])]}}],null,true)},[_c('span',[_vm._v("Lançar manualmente o motorista no caso de o mesmo ter mudado na entrega da encomenda!")])])]}},{key:"item.cobranca",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.cobranca == 1 ? "PAGO" : item.cobranca == 2 ? "À PAGAR" : "CONTRATO")+" ")]}},{key:"item.cancelada",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.cancelada == 1 ? "SIM" : "NÃO")+" ")]}},{key:"item.cod_barras",fn:function(ref){
                  var item = ref.item;
return [_c('MovimentacaoEncomendas',{attrs:{"codBarras":item.cod_barras}})]}},{key:"item.a_pagar",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(item.a_pagar == 1 ? "Sim" : "Não")+" ")]}}])})],1),_c('ReleaseByDrivers',{attrs:{"data":_vm.encomenda}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }